const copyTextToClipboard = function (text) {
	if (navigator.clipboard) {
		return navigator.clipboard.writeText(text);
	}

	const element = document.createElement('span');

	element.textContent = text;
	element.style.whiteSpace = 'pre';
	document.body.appendChild(element);
	const selection = window.getSelection();
	const range = document.createRange();

	selection.removeAllRanges();
	range.selectNode(element);
	selection.addRange(range);
	document.execCommand('copy');
	selection.removeAllRanges();
	document.body.removeChild(element);
};

// eslint-disable-next-line no-undef
copyToClipboard = (elementId) => {
	const aux = document.getElementById(elementId);
	const text = aux.textContent;

	copyTextToClipboard(text);
};
// eslint-disable-next-line no-undef
copyToAllClipboard = () => {
	const btnCopy = document.querySelectorAll('.btn-copy');
	let text = '';

	btnCopy.forEach(function (btn) {
		const aux = btn.previousElementSibling;
		const name = document.getElementById(aux.id + '-name').textContent;

		text += name + ': ' + aux.textContent;
		text += '\n';
	});
	copyTextToClipboard(text);
};
