const header = document.querySelector('.tlw__header');
const headerTop = document.querySelector('.tlw__header__top');
const headerBottom = document.querySelector('.tlw__header__bottom');
const headerDate = document.querySelector('.tlw__header__date');
const openMenu = document.querySelector('.tlw__header__hamburguer');
const menu = document.querySelector('.tlw__header__menu');
const menuItems = document.querySelectorAll('.tlw__header__primary .menu-item-has-children');
let scrollPos = 0;
let resize;

window.addEventListener('load', function () {
	openMenu.addEventListener('click', function () {
		this.classList.toggle('active');
		menu.classList.toggle('active');
	});
	function menuMobile() {
		menuItems.forEach(function (panel) {
			const head = panel.firstChild;

			head.addEventListener('click', (e) => {
				e.preventDefault();
				for (const otherPanel of menuItems) {
					if (otherPanel !== panel) {
						otherPanel.classList.remove('active');
					}
				}
				panel.classList.toggle('active');
			});
		});
	}

	function FixHeader() {
		if (window.scrollY > 0) {
			headerTop.classList.add('active');
			headerBottom.classList.add('active');
			headerDate.classList.add('active');
		} else {
			headerTop.classList.remove('active');
			headerBottom.classList.remove('active');
			headerDate.classList.remove('active');
		}
		setTimeout(FixHeader, 100);
	}

	function checkPosition() {
		const windowY = window.scrollY;

		if (windowY > scrollPos) {
			header.classList.add('active');
		} else {
			header.classList.remove('active');
		}
		scrollPos = windowY;
	}

	function handleRisize(entries) {
		if (entries[0].contentRect.width > 768) {
			window.addEventListener('scroll', checkPosition);
		}
		if (entries[0].contentRect.width < 1025) {
			menuMobile();
		}
	}

	FixHeader();

	resize = new ResizeObserver(handleRisize);

	resize.observe(header);
});
