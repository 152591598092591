import Swiper, { Autoplay, Navigation } from 'swiper';
Swiper.use([Autoplay, Navigation]);

const carrousels = document.querySelectorAll('.tlw__sponsors .swiper');
// eslint-disable-next-line no-unused-vars
let carrousel;

window.addEventListener('load', function () {
	for (let i = 0; i < carrousels.length; i++) {
		carrousels[i].classList.add('carrousel-' + i);
		carrousel = new Swiper('.carrousel-' + i, {
			slidesPerView: 2,
			spaceBetween: 0,
			watchOverflow: true,
			loop: true,
			autoplay: {
				delay: 1000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				769: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: 6,
				},
			},
		});
	}
});
