import on from '../../helpers/on';

const closePopup = document.querySelector('[data-toggle="close-popup"]');
const onlyPopup = document.querySelector('.js-popup-only');
const content = document.querySelector('#tlw-video-popup');

window.addEventListener('load', function () {
	if (!onlyPopup) {
		return;
	}

	on(document, 'click', '[data-toggle="tlw-popup"]', function (event) {
		event.preventDefault();
		const srcVideo = this.dataset.popupSrc;

		content.innerHTML = `<iframe id="player" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%" height="100%" src="https://www.youtube.com/embed/${srcVideo}?rel=0&amp;autoplay=true&amp;enablejsapi=1&amp;widgetid=1"></iframe>`;
		onlyPopup.classList.add('open');
	});

	closePopup.addEventListener('click', function () {
		const player = document.querySelector('#tlw-video-popup #player');

		this.parentNode.classList.remove('open');
		player.classList.remove('open');
		content.innerHTML = '<div id="player"></div>';
	});

	document.addEventListener('click', function (event) {
		if (event.target.className === 'tlw__modal js-popup-only open') {
			if (onlyPopup.classList.contains('open')) {
				onlyPopup.classList.remove('open');
				content.innerHTML = '<div id="player"></div>';
			}
		}
	});
});
